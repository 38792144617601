<template>
    <v-container>
        <h2 class="mt-2">Request Overview</h2>
        <v-container class="px-0">
            <v-row class="justify-space-between">
                <v-col cols="12" md="8">
                    <v-skeleton-loader
                        v-if="isLoading"
                        type="article"
                        class="mt-2"
                    />
                    <v-sheet v-else flat class="pa-4 request-overview">
                        <label class="v-label font-weight-bold">Company</label>
                        <p>{{ companyName }}</p>
                        <label class="v-label font-weight-bold">Category</label>
                        <p>{{ category }}</p>
                        <!-- eslint-disable vue/no-v-html -->
                        <template v-if="description.theme">
                            <label class="v-label font-weight-bold">
                                Recommend a main theme for your Amp
                            </label>

                            <p v-html="format(description.theme)"></p>
                        </template>

                        <template v-if="description.details">
                            <label class="v-label font-weight-bold">
                                What are your target keywords for this campaign?
                            </label>

                            <p v-html="format(description.details)"></p>
                        </template>

                        <template v-if="description.links">
                            <label class="v-label font-weight-bold">
                                Provide a link (or links) that you would like to
                                promote with this Amp
                            </label>

                            <p v-html="format(description.links)"></p>
                        </template>
                        <!-- eslint-enable vue/no-v-html -->

                        <v-btn
                            v-if="
                                authorRequest.frequency &&
                                !authorRequest.rejected_time
                            "
                            fab
                            dark
                            small
                            color="secondary"
                            :to="settingsPage"
                            class="requests-frequency_button"
                        >
                            <v-icon small color="white">cog</v-icon>
                        </v-btn>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="4">
                    <company-card-small
                        :company="authorRequest.company"
                        class="white"
                    />
                </v-col>
            </v-row>
        </v-container>

        <announcements-list
            :announcements="announcements"
            :loading="isLoading"
            :updating="isUpdating"
            class="px-0 pt-0"
            @update="update"
        />

        <paging
            :total="total"
            :default-per-page="perPage"
            :loading="isUpdating"
            size="10"
            class="main-background px-0"
        />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { AnnouncementsList } from '@/components/Announcements/AnnouncementsList';
import { CompanyCardSmall } from '@/components/CompanyCardSmall';
import { Paging } from '@/components/Paging';

import { ListPaged } from '@/mixins';

import { nl2br, urlify } from '@/utils/helpers';

import type { Announcement } from '@/types/Announcement';
import type { AuthorRequest } from '@/types/AuthorRequest';

type AssignmentDescription = {
    details: string;
    theme: string;
    links: string;
};

@Component<Review>({
    components: {
        AnnouncementsList,
        Paging,
        CompanyCardSmall
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class Review extends mixins(ListPaged) {
    endpoint = `/author_requests/review/${this.$route.params.id}`;
    settingsPage = `/author-requests/settings/${this.$route.params.id}`;

    announcements: Announcement[] = [];
    authorRequest: Partial<AuthorRequest> = {};

    get queryParams() {
        return {
            limit: 10,
            page: 1,
            sort: 'priority',
            asc: 1
        };
    }

    get companyName() {
        return this.authorRequest?.company?.name || '';
    }

    get category() {
        return this.authorRequest?.category?.name || '';
    }

    get description(): Partial<AssignmentDescription> {
        if (!this.authorRequest?.description) {
            return {};
        }

        return JSON.parse(this.authorRequest?.description);
    }

    format(text: string) {
        return nl2br(urlify(text));
    }

    onData(data: {
        authorRequest: AuthorRequest;
        announcements: Announcement[];
        length: number;
    }) {
        this.authorRequest = data.authorRequest;
        this.announcements = data.announcements;
        this.total = data.length;
    }
}
</script>

<style lang="scss" scoped>
.request-overview {
    position: relative;
}
.requests-frequency_button {
    position: absolute;
    top: 1em;
    right: 1em;
}
</style>
