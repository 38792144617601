import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c('h2',{staticClass:"mt-2"},[_vm._v("Request Overview")]),_c(VContainer,{staticClass:"px-0"},[_c(VRow,{staticClass:"justify-space-between"},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[(_vm.isLoading)?_c(VSkeletonLoader,{staticClass:"mt-2",attrs:{"type":"article"}}):_c(VSheet,{staticClass:"pa-4 request-overview",attrs:{"flat":""}},[_c('label',{staticClass:"v-label font-weight-bold"},[_vm._v("Company")]),_c('p',[_vm._v(_vm._s(_vm.companyName))]),_c('label',{staticClass:"v-label font-weight-bold"},[_vm._v("Category")]),_c('p',[_vm._v(_vm._s(_vm.category))]),(_vm.description.theme)?[_c('label',{staticClass:"v-label font-weight-bold"},[_vm._v(" Recommend a main theme for your Amp ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.format(_vm.description.theme))}})]:_vm._e(),(_vm.description.details)?[_c('label',{staticClass:"v-label font-weight-bold"},[_vm._v(" What are your target keywords for this campaign? ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.format(_vm.description.details))}})]:_vm._e(),(_vm.description.links)?[_c('label',{staticClass:"v-label font-weight-bold"},[_vm._v(" Provide a link (or links) that you would like to promote with this Amp ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.format(_vm.description.links))}})]:_vm._e(),(
                            _vm.authorRequest.frequency &&
                            !_vm.authorRequest.rejected_time
                        )?_c(VBtn,{staticClass:"requests-frequency_button",attrs:{"fab":"","dark":"","small":"","color":"secondary","to":_vm.settingsPage}},[_c(VIcon,{attrs:{"small":"","color":"white"}},[_vm._v("cog")])],1):_vm._e()],2)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('company-card-small',{staticClass:"white",attrs:{"company":_vm.authorRequest.company}})],1)],1)],1),_c('announcements-list',{staticClass:"px-0 pt-0",attrs:{"announcements":_vm.announcements,"loading":_vm.isLoading,"updating":_vm.isUpdating},on:{"update":_vm.update}}),_c('paging',{staticClass:"main-background px-0",attrs:{"total":_vm.total,"default-per-page":_vm.perPage,"loading":_vm.isUpdating,"size":"10"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }